<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="schools"
          :search="search"
          :provinces="provinces"
          :regencies="regencies"
          :districts="districts"
          :levels="levels"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :package-filter="packageFilter"
          title="List Sekolah"
          subtitle="Halaman untuk menambahkan sekolah ke dalam aplikasi"
          add-btn="Tambah Data"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @open-dialog="newOrderPacket"
          @live-search="searchHandler"
          @filter-level="handleFilterLevel($event)"
          @filter-district="handleFilterDistrict($event)"
          @filter-regencies="handleFilterRegency($event)"
          @filter-province="handleFilterProvince($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable
      v-else
    >
    </SkeletonLoaderTable>

    <v-dialog
      v-model="addFormDialog"
      persistent
      width="1080"
    >
      <v-card>
        <v-card-title
          v-if="dialog === 'add'"
          class="headline justify-center"
        >
          Tambah Sekolah
          <v-icon
            style="position:absolute;top: 20px;right: 20px"
            @click="addFormDialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
        <v-card-title
          v-else-if="dialog === 'edit'"
          class="headline justify-center"
        >
          Edit Sekolah
          <v-icon
            style="position:absolute;top: 20px;right: 20px"
            @click="addFormDialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
        <div class="pa-4">
          <v-tabs
            v-model="tab"
            show-arrows
            fixed-tabs
          >
            <v-tab
              v-for="(tabItem, index) in tabs"
              :key="tabItem.icon"
              :disabled="
                (!disabledTab && tabItem.title === 'Fitur Sekolah') ||
                  (!disabledTab1 && tabItem.title === 'Lihat Paket Buku')
              "
              @click="reRenderComponent(index)"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tabItem.icon }}
              </v-icon>
              <span>{{ tabItem.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            class="pt-6 pb-0"
          >
            <v-tab-item>
              <school-form
                :key="componentKey"
                ref="requiredData"
                :selectedschool.sync="dialog"
                :dataschool.sync="school"
                @selected-packet="getSelectedPacket($event)"
                @next-tab="nextTabHandler"
                @component-valid="disabledComponent($event)"
                @close-dialog="addFormDialog = false"
                @change-data="changeData"
              ></school-form>
            </v-tab-item>
            <v-tab-item>
              <school-feature
                :key="componentKey1"
                :datafeature.sync="school.ability"
                @close-dialog="addFormDialog = false"
                @next-tab="nextTabHandler"
                @prev-tab="prevTabHandler"
              >
              </school-feature>
            </v-tab-item>
            <v-tab-item>
              <school-packet-book
                :key="componentKey2"
                :selectedschool.sync="dialog"
                :selectedpacket.sync="selectedPacket"
                :dataschool.sync="school"
                @close-dialog="addFormDialog = false"
                @prev-tab="prevTabHandler"
                @fetch-school="fetchSchool"
              ></school-packet-book>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :visible="confirmCancels"
      header="Batal Menggunakan Paket ?"
      :is-loading-button="isLoadingButton"
      @destroy-button="destroyPacket"
    >
      <template v-slot:body>
        Apakah anda yakin ingin batal menggunakan paket "{{ packetDetail.name }}" ?
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      :visible="confirmDeleteDialog"
      header="Hapus Sekolah"
      :is-loading-button="isLoadingButton"
      @destroy-button="destroy"
      @close-modal="confirmDeleteDialog = false"
      @close-button="confirmDeleteDialog = false"
    >
      <template v-slot:body>
        <p>Apakah anda yakin ingin menghapus data ini?</p>
        <p>
          Seluruh data dari sekolah ini akan dihapus <b>PERMANENT</b>. Silahkan ketik <b>"Delete School"</b> untuk
          validasi penghapusan Sekolah.
        </p>
        <v-text-field
          v-model="validateDelete"
          label="'Delete School'"
          dense
          type="text"
          outlined
          :rules="[validation.required]"
        ></v-text-field>
      </template>
    </ConfirmDialog>

    <v-dialog
      v-model="secondOrderDialog"
      persistent
      width="1080"
    >
      <v-card>
        <v-card-title class="headline justify-center">
          Belanja Paket Buku
          <v-icon
            style="position:absolute;top: 20px;right: 20px"
            @click="secondOrderDialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="d-flex align-center justify-space-between">
              <v-col
                cols="6"
                class="pl-0"
              >
                <h1>Pilih Paket Buku Baru (Optional)</h1>
                <p>Cari dan pilih buku sesuai kebutuhan paket</p>
              </v-col>

              <v-col
                cols="6"
                class="pr-0"
              >
                <v-autocomplete
                  v-model="packetOption"
                  label="Pilih Paket"
                  :items="packets"
                  item-text="name"
                  item-value="uuid"
                  dense
                  outlined
                  :disabled="packetDetail.uuid ? true : false"
                  @change="getByPacket($event)"
                ></v-autocomplete>
              </v-col>
              <div></div>
            </div>
            <v-divider></v-divider>
            <div class="my-6">
              <h2>Semua Paket</h2>
              <p>Daftar paket buku</p>
              <v-row v-if="packetLoading">
                <v-col
                  v-for="n,index in 12"
                  :key="index"
                  cols="12"
                  md="3"
                >
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col
                  v-for="packet in packets"
                  :key="packet.uuid"
                  md="3"
                >
                  <v-card
                    outlined
                    :class="cardActive == packet.uuid ? 'card-active' : false"
                    :disabled="packetOption"
                    min-height="100"
                    @click="handlerPacketOption(packet.uuid, $event)"
                  >
                    <v-card-text class="">
                      <p class="font-weight-bold mb-2">
                        {{ packet.name }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <div
                v-if="packets.length > 0"
                class="my-4"
              >
                <h2 class="mb-4">
                  Informasi Paket
                </h2>
                <v-row>
                  <v-col md="5">
                    <div class="d-inline-block">
                      <div class="grey lighten-1">
                        Nama Paket
                      </div>
                      <div class="grey lighten-1">
                        Deskripsi
                      </div>
                      <div class="grey lighten-1">
                        Jenjang
                      </div>
                    </div>
                    <div class="d-inline-block ml-4">
                      <div class="black--text">
                        : {{ packetDetail.name }}
                      </div>
                      <div class="black--text">
                        : {{ packetDetail.description }}
                      </div>
                      <div class="black--text">
                        : {{ packetDetail.level.name }}
                      </div>
                    </div>
                  </v-col>
                  <v-col md="5">
                    <div class="d-inline-block">
                      <div>Harga</div>
                      <div>Jumlah Buku</div>
                    </div>
                    <div class="d-inline-block ml-4">
                      <div class="black--text">
                        : <span v-if="packetDetail.price">Rp.{{ packetDetail.price }}</span>
                      </div>
                      <div class="black--text">
                        : <span v-if="packetDetail.total_library">{{ packetDetail.total_library }}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-if="packetDetail.uuid && packets.length > 0">
                <v-btn
                  color="primary"
                  outlined
                  small
                  @click="destroyOption(packetDetail.uuid)"
                >
                  Hapus Pilihan
                </v-btn>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="d-flex align-center justify-space-between">
              <v-col
                cols="6"
                class="pl-0"
              >
                <h1>Pilih Buku</h1>
                <p>Cari dan pilih buku sesuai kebutuhan paket</p>
              </v-col>
              <v-col
                cols="6"
                class="pr-0"
              >
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Cari Buku"
                  single-line
                  hide-details
                  dense
                  outlined
                  class=""
                ></v-text-field>
              </v-col>
            </div>
            <v-row class="px-4">
              <v-col
                cols="6"
                class="pl-0"
              >
                <v-card outlined>
                  <v-card-title>
                    Kategori
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-progress-circular
                    v-if="isLoadingCategory"
                    indeterminate
                    color="primary"
                    class="position-absolute"
                    style="top:60%;left:50%;transform:translate(-50%,-50%);z-index:1"
                  ></v-progress-circular>
                  <v-list
                    height="400"
                    class="overflow-auto"
                    rounded
                  >
                    <v-list-item-group>
                      <v-list-item @click="clickAll">
                        <v-list-item-content>
                          <v-list-item-title>
                            Semua Kategori
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item-title>
                        <v-treeview
                          activatable
                          rounded
                          hoverable
                          else
                          color="primary"
                          :items="categories"
                          item-key="uuid"
                          item-text="name"
                          item-children="sub_categories"
                        >
                          <template
                            slot="label"
                            slot-scope="{ item }"
                          >
                            <a
                              v-if="isLoadingLibrary"
                              class="d-flex justify-space-between align-center"
                              @click.prevent="changeLibraryList(item.uuid, item.total_libraries)"
                            >
                              <span
                                class="d-inline-block text-truncate"
                                style="max-width: 150px"
                              >{{
                                item.name
                              }}</span>
                              <v-chip color="primary"> {{ item.total_libraries }} Buku </v-chip>
                            </a>
                            <a
                              v-else
                              class="d-flex justify-space-between align-center black--text"
                              @click.prevent="changeLibraryList(item.uuid, item.total_libraries)"
                            >
                              <span
                                class="d-inline-block text-truncate"
                                style="max-width: 150px"
                              >{{ item.name }}</span>
                              <v-chip
                                color="primary"
                              >
                                {{ item.total_libraries }} Buku
                              </v-chip>
                            </a>
                          </template>
                        </v-treeview>
                      </v-list-item-title>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                cols="6"
                class="pr-0"
              >
                <v-card outlined>
                  <v-card-title>Pilih Buku</v-card-title>
                  <v-simple-table
                    height="400"
                    class="overflow-auto"
                    fixed-header
                  >
                    <template v-slot:default>
                      <thead style="border-top: none">
                        <tr>
                          <th class="text-left">
                            <v-checkbox
                              v-model="isSelectAll"
                              :disabled="packetDetail.uuid ? true : false"
                              @change="selectAll"
                            >
                            </v-checkbox>
                          </th>
                          <th class="text-left">
                            Type
                          </th>
                          <th class="text-left">
                            Nama Buku
                          </th>
                        </tr>
                      </thead>
                      <v-progress-circular
                        v-if="isLoadingLibrary"
                        indeterminate
                        color="primary"
                        class="position-absolute"
                        style="top:60%;left:50%;transform:translate(-50%,-50%);z-index:1"
                      ></v-progress-circular>
                      <tbody v-else>
                        <tr
                          v-for="(library, index) in libraries"
                          :key="index"
                        >
                          <td v-if="packet_school_uuid === null">
                            <v-checkbox
                              v-model="librariesSchool"
                              :value="library.uuid"
                              @change="handleCheckboxChange(library.uuid, librariesSchool)"
                            >
                            </v-checkbox>
                          </td>
                          <td v-else>
                            <v-checkbox
                              v-model="librariesSchool"
                              disabled
                              :value="library.uuid"
                              @change="handleCheckboxChange(library.uuid, librariesSchool)"
                            >
                            </v-checkbox>
                          </td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <v-chip
                                outlined
                                :class="`${getBadgeStatus(library.type)} text-capitalize`"
                              >
                                {{ library.type }}
                              </v-chip>
                            </div>
                          </td>
                          <td>{{ library.name }}</td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <infinite-loading @infinite="infiniteHandler">
                              <div slot="no-results"></div>
                              <div slot="no-more"></div>
                            </infinite-loading>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-card
                  outlined
                >
                  <v-card-text>
                    <h4>Total Seluruh Buku : {{ total_book }}</h4>
                    <p>Total buku yang sudah dibeli dan yang akan dibeli</p>
                    <div class="d-flex">
                      <div class="me-4">
                        <h4>Total E-Book </h4>
                        <h4>Total Video </h4>
                        <h4>Total Audio </h4>
                      </div>
                      <div>
                        <h4>: {{ totalBook }}</h4>
                        <h4>: {{ totalVideo }}</h4>
                        <h4>: {{ totalAudio }}</h4>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6">
                <div class="notice">
                  <h3>Keterangan :</h3>
                  <div class="d-flex justify-space-between">
                    <div
                      v-for="notice in notices"
                      :key="notice.title"
                    >
                      <div class="d-flex align-center">
                        <v-checkbox
                          v-model="notice.isChecked"
                          disabled
                        ></v-checkbox>
                        <small>{{ notice.title }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center mb-4">
          <v-btn
            color="primary"
            large
            :loading="isLoadingButton"
            :disabled="isLoadingButton"
            @click="updatePacket()"
          >
            Simpan
          </v-btn>
          <v-btn
            color="primary"
            outlined
            large
            @click="closeForm()"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
      <div></div>
    </v-dialog>
    <v-divider></v-divider>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { integerValidator, required } from '@core/utils/validation'
// eslint-disable-next-line object-curly-newline
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiBook, mdiBookMusic, mdiClose, mdiFileVideo, mdiMagnify, mdiMapSearch } from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'
// import ModalDialog from '@/views/components/ModalDialog.vue'

import SchoolFeature from './SchoolFeature.vue'
import SchoolForm from './SchoolForm.vue'
import SchoolPacketBook from './SchoolPacketBook.vue'

export default {
  name: 'SchoolSuperAdmin',
  components: {
    DataTablePagination,
    MainCard,
    SkeletonLoaderTable,
    SchoolForm,
    SchoolPacketBook,
    SchoolFeature,
    ConfirmDialog,
    InfiniteLoading,
  },
  data() {
    return {
      confirmCancels: false,
      isSelectAll: false,
      scrollable: true,
      levels: [
        {
          id: 1,
          name: 'SD',
        },
        {
          id: 2,
          name: 'SMP',
        },
        {
          id: 3,
          name: 'SMA',
        },
        {
          id: 4,
          name: 'SMK',
        },
        {
          id: 5,
          name: 'MA/MTS/MI',
        },
      ],
      validation: {
        required,
        integerValidator,
      },
      validateDelete: null,
      provinces: [],
      regencies: [],
      districts: [],
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingLibrary: false,
      searchDebounce: '',
      secondOrderDialog: false,
      addFormDialog: false,
      editFormDialog: false,
      confirmDeleteDialog: false,
      addMessage: false,
      deleteMessage: false,
      updateMessage: false,
      packageFilter: true,
      page: 1,
      pageLib: 1,
      per_page: 100,
      totalPages: 1,
      totalItems: 0,
      itemsPerPage: 10,
      schools: [],
      icons: {
        mdiMagnify,
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
        mdiMapSearch,
        mdiClose,
      },
      school: {
        uuid: '',
        place_uuid: '',
        npsn: '',
        name: '',
        username: '',
        jenjang: '',
        tahun_ajaran: '',
        semester: '',
        address: '',
        image: null,
        place: {
          address: '',
          district: {},
          regencies: {},
          province: {},
        },
        ability: [],
        package_uuid: '',
      },
      headers: [
        { text: '#', value: 'index' },
        { text: 'ID Sekolah', value: 'npsn' },
        { text: 'Nama Sekolah', value: 'name' },
        { text: 'Provinsi', value: 'place.province.name' },
        { text: 'Kabupaten', value: 'place.regencies.name' },
        { text: 'Kecamatan', value: 'place.district.name' },
        { text: 'Alamat', value: 'place.address' },
        { text: 'Aksi', value: 'actions' },
      ],
      tab: '',
      tabs: [{ title: 'Tambah Sekolah' }, { title: 'Fitur Sekolah' }, { title: 'Lihat Paket Buku' }],

      formData: [],
      libraries: [],
      categories: [],
      notices: [
        { title: 'Buku yang akan dibeli', isChecked: true },
        { title: 'Buku yang sudah dibeli', isChecked: true },
        { title: 'Buku yang tidak dibeli', isChecked: false },
      ],
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        type: {},
      },
      librariesSchool: [],
      total_book: 0,
      librariesToAdd: [],
      librariesToRemove: [],
      librariesSelected: [],
      timeout: null,
      packet: {
        total_library: 0,
      },
      packets: [],
      packetLoading: false,
      isLoadingCategory: false,
      selectedPacket: {},
      dialog: 'add',
      libraryOld: [],
      totalItemsLib: 0,
      totalPagesLib: 100,
      packetOption: '',
      school_name: '',
      province_uuid: '',
      district_uuid: '',
      regency_uuid: '',
      packetClick: '',
      level: '',
      service: 'libraryschool',
      total_type: {
        audio: 0,
        video: 0,
        ebook: 0,
      },
      packetDetail: {
        level: {},
      },
      packet_school_uuid: null,
      cardActive: false,
      totalAudio: 0,
      totalBook: 0,
      totalVideo: 0,
      totalBookActive: 0,
      componentKey: 0,
      componentKey1: 0,
      componentKey2: 0,
      componentValid: false,
      disabledTab: false,
      disabledTab1: false,
      categoryUuid: null,
    }
  },
  computed: {
    search: {
      get() {
        return this.searchDebounce
      },
      set(val) {
        this.scrollable = false
        if (val.length > 3 || val.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.searchDebounce = val
            this.listLibrary({ library: this.searchDebounce })
          }, 500)
        }
      },
    },
  },
  watch: {
    page: {
      handler() {
        this.listSchool({ search: this.school_name })
      },
    },
  },
  mounted() {
    this.listSchool()
    this.listProvince()
    this.listRegency()
    this.listDistrict()
  },
  methods: {
    setPacketUuid(uuid) {
      this.selectedPacket = uuid
    },
    disabledComponent(formValid) {
      this.componentValid = formValid
    },
    async infiniteHandler($state) {
      this.pageLib += 1

      if (this.categoryUuid) {
        await this.$services.ApiServices.getParams('librarycategory', this.categoryUuid, {
          per_page: this.per_page,
          page: this.pageLib,
        }).then(
          ({ data }) => {
            if (data.data.length) {
              this.libraries = [...this.libraries, ...data.data]
              this.libraries = this.libraries.map(item => ({
                ...item,
                typeLibrary: {
                  audio: item.audio,
                  video: item.video,
                  ebook: item.ebook,
                },
              }))
              this.isLoadingLibrary = false
              $state.loaded()
            } else {
              $state.complete()
            }
            // this.libraries.sort((a, b) => Number(b.selected) - Number(a.selected))
          },
          err => console.error(err),
        )
        await this.selectAll()
      } else {
        await this.$services.ApiServices.list('library', {
          per_page: this.per_page,
          page: this.pageLib,
        }).then(({ data }) => {
          if (data.data.length) {
            this.libraries = [...this.libraries, ...data.data]
            this.libraries = this.libraries.map(item => ({
              ...item,
              typeLibrary: {
                audio: item.audio,
                video: item.video,
                ebook: item.ebook,
              },
            }))
            this.isLoadingLibrary = false
            $state.loaded()
          } else {
            $state.complete()
          }
        })
      }
    },
    reRenderComponent(index) {
      if (index === 0) {
        this.disabledTab = false
        this.disabledTab1 = false
      } else if (index === 1) {
        this.disabledTab = true
        this.disabledTab1 = false
      }
      this.componentKey += 1
    },
    resetForm() {
      this.school.uuid = ''
      this.school.place_uuid = ''
      this.school.npsn = ''
      this.school.name = ''
      this.school.username = ''
      this.school.jenjang = ''
      this.school.tahun_ajaran = ''
      this.school.semester = ''
      this.school.address = ''
      this.school.image = null
      this.school.district_uuid = ''
      this.school.regencies_uuid = ''
      this.school.province_uuid = ''
      this.school.package_uuid = ''
      this.school.data_school = ''
      this.school.school_year = ''
      this.school.level = ''

      this.school.place = {
        address: '',
        district: {},
        regencies: {},
        province: {},
      }
      this.school.ability = []
      this.cardActive = false
    },
    closeHandler() {
      this.addFormDialog = false
      this.resetForm()
    },
    clickAll() {
      this.scrollable = true
      this.listLibrary()
    },
    checkButton() {
      this.librariesSchool = []
      this.total_book = this.librariesSchool.length
    },
    getBadgeType(type) {
      if (type === null || type === 'null' || type === '' || type === undefined) {
        return 'v-chip-light-bg error--text'
      }

      return 'v-chip-light-bg success--text'
    },
    getTextType(type) {
      if (type === null || type === 'null' || type === '' || type === undefined) {
        return '-'
      }

      return '+'
    },
    getIconType(icon) {
      if (icon === 'ebook') {
        return mdiBook
      }
      if (icon === 'audio') {
        return mdiBookMusic
      }

      return mdiFileVideo
    },
    getBadgeStatus(type) {
      if (type === 'ebook') {
        return 'v-chip-light-bg warning--text'
      }
      if (type === 'audio') {
        return 'v-chip-light-bg success--text'
      }

      return 'v-chip-light-bg primary--text'
    },
    searchHandler(search) {
      this.school_name = search
      this.listSchool({
        jenjang: this.level,
        province_uuid: this.province_uuid,
        regency_uuid: this.regency_uuid,
        district_uuid: this.district_uuid,
      })
    },
    handleFilterLevel(level) {
      this.level = level
      if (level === null) this.level = ''

      return this.listSchool()
    },
    handleFilterProvince(province) {
      this.province_uuid = province

      if (province !== null) {
        this.listRegency()
      } else {
        this.province_uuid = ''
        this.listRegency({ province_uuid: '' })
      }

      return this.listSchool()
    },
    handleFilterRegency(regency) {
      this.regency_uuid = regency
      if (regency !== null) {
        this.listDistrict()
      } else {
        this.regency_uuid = ''
        this.listDistrict({ regencies_uuid: '' })
      }

      return this.listSchool()
    },
    handleFilterDistrict(district) {
      this.district_uuid = district
      if (district === null) {
        this.district_uuid = ''
        this.listDistrict({ regencies_uuid: '' })
      }

      return this.listSchool()
    },
    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },
    async listRegency() {
      await this.$services.ApiServices.list('regency', { per_page: 'all', province_uuid: this.province_uuid }).then(
        ({ data }) => {
          this.regencies = data.data
        },
        err => console.error(err),
      )
    },
    async listDistrict() {
      await this.$services.ApiServices.list('district', { per_page: 'all', regencies_uuid: this.regency_uuid }).then(
        ({ data }) => {
          this.districts = data.data
        },
        err => console.error(err),
      )
    },

    async updatePacket() {
      this.isLoadingButton = true
      const libraryNew = this.librariesSchool.filter(person => !this.libraryOld.includes(person))
      this.formData = new FormData()
      libraryNew.forEach(el => {
        this.formData.append('library_uuid[]', el)
      })
      if (this.packet_school_uuid !== null) {
        this.formData.append('package_uuid', this.packet_school_uuid)
        this.formData.delete('library_uuid[]')
      }
      this.formData.append('school_uuid', this.school.uuid)
      if (libraryNew.length < 1 && this.packet_school_uuid === null) {
        this.showSnackbar({
          text: 'Cannot Remove your current library!!, please add one',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      await this.$services.ApiServices.add('libraryschool', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.secondOrderDialog = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listPacket()
      this.listSchool()
      this.resetForm()
    },
    async newOrderPacket(uuid) {
      this.packet_school_uuid = null
      this.packetDetail = {
        level: '',
      }
      this.packetOption = null
      this.libraryOld = []
      this.librariesSchool = []
      this.total_book = 0
      this.school.uuid = uuid
      this.secondOrderDialog = true

      if (this.packets.length < 1) this.listPacket()
      if (this.categories.length < 1) this.listCategory()
      if (this.categories.length < 1) this.listLibrary()

      await this.$services.ApiServices.getParams('library', uuid, { per_page: 'all' }).then(
        async ({ data }) => {
          const librariesData = data.data

          librariesData.forEach(item => {
            item.selected = this.librariesSchool.includes(item.uuid)
            if (!item.selected && this.isSelectAll) {
              this.librariesSchool.push(item.uuid)
              this.libraryOld.push(item.uuid)
            }
          })
          this.total_book = data.meta.total
          this.totalBook = data.total_ebook
          this.totalAudio = data.total_audio_book
          this.totalVideo = data.total_video_book
        },
        err => console.error(err),
      )
    },
    async getByPacket(uuid) {
      this.scrollable = false
      this.isLoadingLibrary = true
      this.packetOption = uuid
      await this.$services.ApiServices.get('packet', uuid).then(
        async ({ data }) => {
          const librariesData = data.data.library

          librariesData.forEach(item => {
            item.selected = this.librariesSchool.includes(item.uuid)
            if (!item.selected && this.isSelectAll) this.librariesSchool.push(item.uuid)
          })
          this.libraries = data.data.library.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.total_book = this.librariesSchool.length
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },
    async listSchool(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('school', {
        ...params,
        page: this.page,
        per_page: 10,
        search: this.school_name,
        jenjang: this.level,
        province_uuid: this.province_uuid,
        regency_uuid: this.regency_uuid,
        district_uuid: this.district_uuid,
      }).then(
        ({ data }) => {
          this.schools = data.data.map((school, index) => ({
            ...school,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async listPacket() {
      this.packetLoading = true
      await this.$services.ApiServices.list('packet', { per_page: 'all' }).then(
        ({ data }) => {
          this.packets = data.data
          this.packetLoading = false
        },
        err => console.error(err),
      )
    },
    async listCategory() {
      this.isLoadingCategory = true
      await this.$services.ApiServices.list('category', { per_page: 'all', parent: 1 }).then(
        ({ data }) => {
          this.categories = data.data
          this.isLoadingCategory = false
        },
        err => console.error(err),
      )
    },
    confirmCancel() {
      this.dialog = ''
      this.confirmCancels = true
    },
    destroyPacket() {
      this.packetDetail = {
        level: '',
      }
      this.packet_school_uuid = null
      this.confirmCancels = false
    },
    async listLibrary(params = {}) {
      this.isLoadingLibrary = true
      await this.$services.ApiServices.list('library', {
        ...params,
        per_page: this.per_page,
        page: this.pageLib,
      }).then(
        ({ data }) => {
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.totalItemsLib = data.meta.total
          this.totalPagesLib = data.meta.per_page
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },
    async changeLibraryList(uuid, total) {
      this.categoryUuid = uuid
      this.totalBookActive = total
      this.scrollable = false
      this.isLoadingLibrary = true
      this.pageLib = 1
      if (this.totalBookActive < 10 || this.totalBookActive === 10) {
        await this.$services.ApiServices.getParams('librarycategory', uuid, {
          per_page: this.per_page,
          page: this.pageLib,
        }).then(
          ({ data }) => {
            const librariesData = data.data

            librariesData.forEach(item => {
              item.selected = this.librariesSchool.includes(item.uuid)
              if (!item.selected && this.isSelectAll) this.librariesSchool.push(item.uuid)
            })
            this.libraries = data.data.map(item => ({
              ...item,
              typeLibrary: {
                audio: item.audio,
                video: item.video,
                ebook: item.ebook,
              },
            }))
            // this.totalItemsLib = data.meta.total
            // this.totalPagesLib = data.meta.per_page
            // this.libraries.sort((a, b) => Number(b.selected) - Number(a.selected))
            this.total_book = this.librariesSchool.length
          },
          err => console.error(err),
        )
      } else {
        await this.$services.ApiServices.getParams('librarycategory', uuid, {
          per_page: this.per_page,
          page: this.pageLib,
        }).then(
          ({ data }) => {
            const librariesData = data.data

            librariesData.forEach(item => {
              item.selected = this.librariesSchool.includes(item.uuid)
              if (!item.selected && this.isSelectAll) this.librariesSchool.push(item.uuid)
            })
            this.libraries = data.data.map(item => ({
              ...item,
              typeLibrary: {
                audio: item.audio,
                video: item.video,
                ebook: item.ebook,
              },
            }))
            this.totalItemsLib = data.meta.total
            this.totalPagesLib = data.meta.per_page
            // this.libraries.sort((a, b) => Number(b.selected) - Number(a.selected))
            this.total_book = this.librariesSchool.length
          },
          err => console.error(err),
        )
      }
      this.isLoadingLibrary = false
    },
    async handlerPacketOption(uuid) {
      await this.$services.ApiServices.get('packet', uuid).then(
        ({ data }) => {
          this.packetDetail = data.data
          this.packet_school_uuid = this.packetDetail.uuid
          this.cardActive = uuid
        },
        err => console.error(err),
      )
    },
    handleCheckboxChange(libraryUuid, data) {
      this.total_book = data.length
    },
    async selectAll() {
      if (this.isSelectAll) {
        const selected = this.libraries.map(library => library.uuid)
        this.librariesSchool = selected
        this.total_book = this.librariesSchool.length
        // console.log(this.librariesSchool)
      } else {
        this.librariesSchool = []
        this.total_book = this.librariesSchool.length
      }
    },
    async getSelectedLibraries() {
      await this.$services.ApiServices.get('packet', this.packetData.uuid).then(
        ({ data }) => {
          if (data.data.library) {
            this.librariesSelected = data.data.library
            this.packet = { ...this.packet, total_library: data.data.total_library }
          } else this.librariesSelected = []
        },
        err => console.error(err),
      )
    },
    async getPacket() {
      await this.$services.ApiServices.get('packet', this.packetData.uuid).then(
        ({ data }) => {
          this.packet = data.data
        },
        err => console.error(err),
      )
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.addFormDialog = true
      this.tab = 0
      this.selectedPacket = {}
      await this.$services.ApiServices.get('school', uuid).then(
        ({ data }) => {
          this.school = { ...data.data }
        },
        err => console.error(err),
      )
    },
    async destroy() {
      if (this.validateDelete === null || this.validateDelete !== 'Delete School') {
        this.showSnackbar({
          text: "Please, type 'Delete School' first !!! ",
          color: 'error',
        })

        return
      }
      this.isLoadingButton = true
      const schoolDelete = await this.$services.ApiServices.destroy('school', this.school.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.resetForm()
          this.isLoadingButton = false
          this.confirmDeleteDialog = false

          return data
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listSchool({
        search: this.school_name,
        jenjang: this.level,
        province: this.province_name,
        regencies: this.regency_name,
        district: this.district_name,
      })
    },
    destroyOption(uuid) {
      if (uuid !== null) {
        this.cardActive = false
        this.packetDetail = {
          level: {},
        }
      }
    },
    fetchSchool() {
      this.listSchool()
    },
    changeData() {
      this.school = { ...this.$refs.requiredData.$data.school }
    },
    confirmDestroy(uuid) {
      this.school.uuid = uuid
      this.validateDelete = null
      this.confirmDeleteDialog = true
    },
    getSelectedPacket(uuid) {
      this.$services.ApiServices.get('packet', uuid).then(
        ({ data }) => {
          this.selectedPacket = data.data
        },
        err => console.error(err),
      )
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showFormAdd() {
      this.dialog = 'add'
      this.addFormDialog = true
      this.disabledTab = false
      this.disabledTab1 = false
      this.resetForm()
      this.school = { ...this.school }
      if (localStorage.getItem('feature') !== 'undefined') localStorage.removeItem('feature')
      this.tab = 0
    },
    closeForm() {
      this.secondOrderDialog = false
      this.resetForm()
    },

    nextTabHandler() {
      this.disabledTab = true
      if (this.tab === 1) {
        this.disabledTab1 = true
      }
      this.tab += 1
    },
    prevTabHandler() {
      this.disabledTab = false
      if (this.tab === 2) {
        this.disabledTab = true
        this.disabledTab1 = false
      }
      this.tab -= 1
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scooped>
.school-packet-header .header-left ul {
  margin-left: 0;
  /* border: 1px solid #000; */
}

.school-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}

.card-active {
  outline: 2px solid #ec008c !important;
  background: rgba(236, 0, 140, 0.05) !important;
}

.v-list-item {
  min-height: 40px;
  margin-bottom: 0 !important;
}
.v-application a {
  color: #746d7d;
  text-decoration: none;
}
</style>
