<template>
  <div
    class="schoolFeature"
  >
    <v-row class="mb-0 py-4 px-0 px-md-4 px-lg-4 px-xl-4">
      <v-col
        v-for="feature in features"
        :key="feature.title"
        md="2"
        cols="6"
        class="my-4"
      >
        <div
          class="d-flex align-center"
          style="overflow-wrap: anywhere;"
        >
          <div
            class=""
          >
            <v-checkbox
              v-model="featureSchool"
              :value="feature.subject"
              class="d-flex align-center"
              @change="handleCheckboxChange(feature.uuid, featureSchool)"
            ></v-checkbox>
          </div>
          <div
            class="mx-1"
          >
            <v-icon>{{ feature.icon }}</v-icon>
          </div>
          <div>
            <small>{{ feature.title }}</small>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-center mb-1">
      <v-btn
        color="primary"
        outlined
        class="mx-2"
        large
        @click="prevTab"
      >
        Sebelumnya
      </v-btn>
      <v-btn
        color="primary"
        class="mx-2"
        large
        @click="add"
      >
        Selanjutnya
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiBellRingOutline,
  mdiBookAccountOutline,
  mdiBookOpenPageVariantOutline,
  mdiBookshelf,
  mdiCalendarMonthOutline,
  mdiCardAccountDetailsOutline,
  mdiCastEducation,
  mdiClipboardAccountOutline,
  mdiClockAlert,
  mdiDesktopClassic,
  mdiFileDocumentMultipleOutline,
  mdiFileImportOutline,
  mdiForumOutline,
  mdiHandWaveOutline,
  mdiImageAlbum,
  mdiNoteEditOutline,
  mdiPencilBoxMultipleOutline,
  mdiPoll,
  mdiSchoolOutline,
  mdiTextAccount,
  mdiTownHall,
} from '@mdi/js'

export default {
  props: {
    datafeature: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCardAccountDetailsOutline,
        mdiTownHall,
        mdiCalendarMonthOutline,
        mdiBellRingOutline,
        mdiFileImportOutline,
        mdiImageAlbum,
        mdiFileDocumentMultipleOutline,
        mdiBookOpenPageVariantOutline,
        mdiDesktopClassic,
        mdiForumOutline,
        mdiSchoolOutline,
        mdiClockAlert,
        mdiClipboardAccountOutline,
        mdiPencilBoxMultipleOutline,
        mdiBookshelf,
        mdiPoll,
        mdiCastEducation,
        mdiBookAccountOutline,
        mdiNoteEditOutline,
        mdiTextAccount,
        mdiHandWaveOutline,
      },
      featureSchool: [
        'SchoolSchool',
        'FunctionarySchool',
        'LessonSchool',
        'StudentSchool',
        'CalendarSchool',
        'AttendanceSchool',
        'EraportSchool',
        'EvotingSchool',
        'SliderSchool',
        'NewsSchool',
        'CbtSchool',
        'AnnouncementSchool',
        'GreetingSchool',
        'ViolationSchool',
      ],
      features: [
        { title: 'Sekolah', icon: mdiTownHall, subject: 'SchoolSchool' },
        { title: 'Fungsionaris', icon: mdiClipboardAccountOutline, subject: 'FunctionarySchool' },
        { title: 'Pelajaran', icon: mdiBookshelf, subject: 'LessonSchool' },
        { title: 'Peserta Didik', icon: mdiSchoolOutline, subject: 'StudentSchool' },
        { title: 'Kalender', icon: mdiCalendarMonthOutline, subject: 'CalendarSchool' },
        { title: 'Absensi', icon: mdiClockAlert, subject: 'AttendanceSchool' },
        { title: 'E-Raport', icon: mdiBookAccountOutline, subject: 'EraportSchool' },
        { title: 'E-Voting', icon: mdiTextAccount, subject: 'EvotingSchool' },
        { title: 'Slider', icon: mdiImageAlbum, subject: 'SliderSchool' },
        { title: 'Berita', icon: mdiFileDocumentMultipleOutline, subject: 'NewsSchool' },
        { title: 'Pengumuman', icon: mdiBellRingOutline, subject: 'AnnouncementSchool' },
        { title: 'Sambutan', icon: mdiHandWaveOutline, subject: 'GreetingSchool' },
        { title: 'CBT', icon: mdiCastEducation, subject: 'CbtSchool' },
        { title: 'Violation', icon: mdiNoteEditOutline, subject: 'ViolationSchool' },
      ],
      formValid: false,
    }
  },
  watch: {
    datafeature() {
      this.assignValue()
    },
    featureSchool: {
      handler() {
        const valid = []
        const requiredField = ['featureSchool']
        Object.entries(this.featureSchool).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
  },
  mounted() {
    this.assignValue()
  },
  methods: {
    assignValue() {
      if (this.datafeature.length > 0) {
        this.featureSchool = []
        this.datafeature.forEach(el => {
          this.featureSchool.push(el.subject)
        })
      }
    },
    async add() {
      localStorage.setItem('feature', JSON.stringify(this.featureSchool))
      this.$emit('next-tab')
    },
    prevTab() {
      localStorage.removeItem('feature')
      this.$emit('prev-tab')
    },
    handleCheckboxChange(featureValue, isSelected) {},
  },
}
</script>

<style>
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 2px;
}
.v-input--selection-controls {
  margin-top: 14px;
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
</style>
