<template>
  <div class="schoolForm">
    <v-row class="py-5">
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="school.npsn"
            label="ID/NPSN Sekolah"
            dense
            type="text"
            outlined
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="school.name"
            label="Nama Sekolah"
            dense
            type="text"
            outlined
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="school.jenjang"
            :items="levels"
            label="Jenjang"
            item-text="title"
            item-value="title"
            dense
            clearable
            outlined
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="school.semester"
            :items="semesters"
            label="Semester"
            item-text="title"
            item-value="title"
            dense
            outlined
            clearable
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col>
        <div>
          <v-autocomplete
            v-model="school.tahun_ajaran"
            :items="periode"
            label="Tahun Ajaran"
            item-text="title"
            item-value="title"
            dense
            clearable
            outlined
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="school.place.province"
            label="Provinsi"
            item-text="name"
            item-value="uuid"
            dense
            :items="provinces"
            outlined
            clearable
            :disabled="!provinces.length"
            @change="changeListRegencies()"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="school.place.regencies"
            :items="regenciesByProvince"
            :loading="loadingRegencies"
            label="Kabupaten/Kota"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            clearable
            :disabled="!regenciesByProvince.length"
            @change="changeListDistrict()"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="school.place.district"
            :items="districtsByRegencies"
            :loading="loadingDistrict"
            label="Kecamatan"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            clearable
            outlined
            :disabled="!districtsByRegencies.length"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div>
          <v-textarea
            v-model="school.place.address"
            label="Alamat"
            dense
            outlined
          ></v-textarea>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div>
          <h4 class="mb-4 mt-5">
            Informasi Login
          </h4>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-if="selectedschool === 'edit'"
            v-model="school.username"
            label="Username"
            dense
            disabled
            type="text"
            outlined
          ></v-text-field>
          <v-text-field
            v-if="selectedschool === 'add'"
            v-model="school.username"
            label="Username"
            dense
            type="text"
            outlined
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="school.password"
            :append-icon="show ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
            :type="show ? 'text' : 'password'"
            label="Password"
            dense
            outlined
            @click:append="show = !show"
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="12"
        lg="12"
        md="12"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="school.data_school"
            label="Link google drive"
            dense
            type="text"
            outlined
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div>
          <FileInput
            v-model="initialFile"
            :value="school.image"
            :prependicon="icons.mdiImageMultipleOutline"
            label="Foto Profil"
            outlined

            dense
            @input="getFile"
          ></FileInput>
        </div>
      </v-col>

      <v-col
        v-if="selectedschool === 'add'"
        cols="12"
        class="py-0"
      >
        <div>
          <h4 class="mb-4 mt-5">
            Pilih Paket Buku
          </h4>
        </div>
      </v-col>
      <v-col
        v-if="selectedschool === 'add'"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="school.packet"
            label="Paket Buku"
            item-text="name"
            item-value="uuid"
            dense
            :items="packets"
            outlined
            clearable
            @change="getSelectedPacket"
          >
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-center mb-1 mt-7">
      <v-btn
        color="primary"
        outlined
        class="mx-2"
        large
        @click="$emit('close-dialog')"
      >
        Batal
      </v-btn>
      <v-btn
        color="primary"
        class="mx-2"
        large
        :disabled="!formValid || (!school.packet && selectedschool ==='add')"
        @click="add"
      >
        Selanjutnya
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline, mdiImageMultipleOutline } from '@mdi/js'
import FileInput from '@/views/components/FileInput.vue'

export default {
  components: {
    FileInput,
  },
  props: {
    selectedschool: {
      type: String,
      default: () => '',
    },
    dataschool: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      icons: {
        mdiImageMultipleOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      show: false,
      school: {
        data_school: '',
        role: 'school',
        packet: '',
        npsn: '',
        name: '',
        username: '',
        password: '',
        image: null,
        jenjang: '',
        tahun_ajaran: '',
        semester: '',
        place: {
          address: '',
          district: {},
          regencies: {},
          province: {},
        },
      },
      loadingRegencies: false,
      loadingDistrict: false,
      addFormDialog: false,
      semesters: [
        {
          id: 1,
          title: 'Ganjil',
        },
        {
          id: 2,
          title: 'Genap',
        },
      ],
      levels: [
        {
          id: 1,
          title: 'SD',
        },
        {
          id: 2,
          title: 'SMP',
        },
        {
          id: 3,
          title: 'SMA',
        },
        {
          id: 4,
          title: 'SMK',
        },
        {
          id: 5,
          title: 'MA/MTS/MI',
        },
      ],
      periode: [
        {
          id: 1,
          title: '2021/2022',
        },
        {
          id: 2,
          title: '2022/2023',
        },
        {
          id: 3,
          title: '2023/2024',
        },
        {
          id: 4,
          title: '2024/2025',
        },
        {
          id: 5,
          title: '2025/2026',
        },
        {
          id: 6,
          title: '2026/2027',
        },
        {
          id: 7,
          title: '2027/2028',
        },
        {
          id: 8,
          title: '2028/2029',
        },
        {
          id: 9,
          title: '2029/2030',
        },
      ],
      provinces: [],
      regencies: [],
      districts: [],
      packets: [],
      regenciesByProvince: [],
      districtsByRegencies: [],
      cardActive: false,
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.school.image === 'string') {
          return new File([this.school.image], this.school.image, { type: 'text/plain' })
        }

        return this.school.image
      },
      set() {
        return this.school.image
      },
    },
  },
  watch: {
    school: {
      handler() {
        const valid = []
        const requiredField = [
          'npsn',
          'name',
          'jenjang',
          'tahun_ajaran',
          'semester',
          'username',
          'password',
          'data_school',
        ]
        Object.entries(this.school).forEach(([key, item]) => {
          if (requiredField.includes(key)) {
            if (requiredField.includes(key) && Object.keys(item).length !== 0) {
              valid.push(!!(item && Object.keys(item).length))
            } else {
              valid.push(!!(item && item.length))
            }
          }
        })
        this.formValid = !valid.includes(false)
        this.$emit('component-valid', this.formValid)
      },
      deep: true,
    },
    dataschool() {
      if (this.dataschool !== undefined) {
        this.assignValue()
        this.changeListRegencies()
        this.changeListDistrict()
      }
    },
  },
  async mounted() {
    await this.listLevel()
    await this.listProvince()
    this.listPacket()
    if (this.dataschool !== undefined) {
      this.assignValue()
      this.changeListRegencies()
      this.changeListDistrict()
    }
  },
  methods: {
    assignValue() {
      this.school = { ...this.dataschool }
    },
    async changeListRegencies() {
      this.loadingRegencies = true
      await this.$services.ApiServices.list('regency', {
        per_page: 'all',
        province_uuid:
          typeof this.school.place.province === 'object' && this.school.place.province !== null
            ? this.school.place.province.uuid
            : this.school.place.province,
      }).then(
        ({ data }) => {
          this.regenciesByProvince = data.data
        },
        err => console.error(err),
      )
      this.loadingRegencies = false
    },
    async changeListDistrict() {
      this.loadingDistrict = true
      await this.$services.ApiServices.list('district', {
        per_page: 'all',
        regencies_uuid: this.school.place.regencies?.uuid,
      }).then(
        ({ data }) => {
          this.districtsByRegencies = data.data
        },
        err => console.error(err),
      )
      this.loadingDistrict = false
    },

    async add() {
      localStorage.setItem('school_form', JSON.stringify(this.school))
      this.$emit('change-data')
      this.$emit('next-tab', this.school)
    },
    async listLevel() {
      await this.$services.ApiServices.list('level', { per_page: 'all' }).then(
        ({ data }) => {
          this.level = data.data
        },
        err => console.error(err),
      )
    },
    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },
    listPacket() {
      this.$services.ApiServices.list('packet', { per_page: 'all' }).then(
        ({ data }) => {
          this.packets = data.data
        },
        err => console.error(err),
      )
    },
    getSelectedPacket() {
      if (this.school.packet !== null) this.$emit('selected-packet', this.school.packet)
    },

    getFile(data) {
      this.school.image = data
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
